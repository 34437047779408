import { BrowserRouter, Route, Routes} from 'react-router-dom';
import React, { Suspense } from 'react';
import { GlobalStyle } from './style/global';
import ModalProvider from './context/ModalContext';
import PlaygroundProvider from './context/PlaygroundContext';

// Lazy imports for components
const Home = React.lazy(() => import('./screens/Home'));
const Playground = React.lazy(() => import('./screens/Playground'));
const Error404 = React.lazy(() => import('./screens/Error404'));
const TermsOfUse = React.lazy(() => import('./screens/Terms/Termsofuse'));
const Privacy = React.lazy(() => import('./screens/Terms/Privacy'));
const Contact = React.lazy(() => import('./screens/Terms/Contact'));
const AboutUs = React.lazy(() => import('./screens/Terms/AboutUs'));

function App() {
  return (
    <PlaygroundProvider>
      
      <ModalProvider>
        <BrowserRouter>
          <GlobalStyle />
          
            <Suspense fallback={<div className="loader-container"><div className="loader"></div></div>}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/playground/:folderId/:playgroundId" element={<Playground />} />
                <Route path="*" element={<Error404 />} />
                <Route path="/terms" element={<TermsOfUse />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/aboutus" element={<AboutUs />} />
                </Routes>
            </Suspense>
          
        </BrowserRouter>
      </ModalProvider>
    </PlaygroundProvider>
  );
}

export default App;
